<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-4">Graphique des utilisateurs</h4>
            <vue-echarts :option="option.doughnutChart" style="height: 240px" />
        </div>
    </div>
</template>

<script>
import { VueEcharts } from "vue3-echarts";

export default {
    props: {
        serieData:Array,
    },
    components: {
        VueEcharts,
    },
    data() {
        return {
            doughnutChart: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    x: 'left',
                    data: ['Utilisateurs vérifiés', 'Utilisateurs non vérifiés'],
                    textStyle: {
                        color: '#999'
                    }
                },
                color: ['#556ee6', '#f1b44c', '#f46a6a', '#50a5f1', '#34c38f'],
                series: [{
                    name: 'Total',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    emphasis: {
                        label: {
                            show: true,

                        }
                    },

                    normal: {
                        show: false
                    },
                    data: this.serieData
                }]
            },
        }
    },
    computed: {
        option() {
            return {
                doughnutChart: this.doughnutChart,
            };
        },
    },
}
</script>

<style></style>